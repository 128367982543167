import { Container, Row, Col } from "react-bootstrap";
import Certificate from "../pictures/certificate.svg";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
      >
        <Container fluid className="fluidcolor d-flex al-center">
          <div className="shadow-layer"></div>
          <Container>
            <Row>
              <Col className="d-flex ">
                <h1>{t("aboutcompany")}</h1>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container>
          <Row>
            <Col className="mt-34 about-desc on-photo">
              <p className="hyperlink" dangerouslySetInnerHTML={{__html: t("aboutdescriptionone")}}></p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="mt-34">
            <Col className="d-flex jc-center al-start ">
              <img className="cert" src={Certificate} alt="certificate" />
            </Col>
            <Col className="about-desc mt-34-media-992px">
              <p>{t("aboutdescriptiontwo")}</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="mt-34 about-desc">
              <p>{t("aboutdescriptionthree")}</p>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </div>
  );
};

export default About;
