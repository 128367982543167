import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;


const Contact = () => {
  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
    >
        <Container fluid className="p-0">
          <Row>
            <Col>
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    title="map"
                    class="gmap_iframe"
                    width="100%"
                    height="600px"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Gftc&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                  <a href="http://embed-google-maps.com/"></a>
                </div>
              </div>

              {/* <SimpleMap /> */}
            </Col>
          </Row>
        </Container>
      </motion.div>
     </div>
  );
};

//   function SimpleMap(){
//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '100vh', width: '100%' }}>
//       <GoogleMapReact
//         defaultZoom={20}
//         bootstrapURLKeys={{ key: "" }}
//         defaultCenter={{lat: 41.71254138724253, lng:44.760785584657384 }}
//       >
//         <AnyReactComponent
//           lat={59.955413}
//           lng={30.337844}
//           text="GFTC"
//         />
//       </GoogleMapReact>
//     </div>
//   );
// }

export default Contact;



// AIzaSyAj15XvR2XAtHLaW1-xVlrD0aBd7M59MfA