export const partners = [
    {
      title: "ბაზის ბანკი",
      img: require("../pictures/partnersslider/bb.svg").default,
    },
    {
      title: "ქართუ ბანკი",
      img: require("../pictures/partnersslider/cartu.svg").default,
    },
    {
      title: "თიბისი ბანკი",
      img: require("../pictures/partnersslider/tbc.svg").default,
    },
    {
      title: "ვითიბი ბანკი",
      img: require("../pictures/partnersslider/vtb.svg").default,
    },
    {
      title: "ლიბერთი ბანკი",
      img: require("../pictures/partnersslider/liberty.svg").default,
    },
    {
      title: "საქართველოს ბანკი",
      img: require("../pictures/partnersslider/bog.svg").default,
    },
    {
      title: "ჰალიკ ბანკი",
      img: require("../pictures/partnersslider/halyk.svg").default,
    },
    {
      title: "დავრ ბანკი",
      img: require("../pictures/partnersslider/davrbank.svg").default,
    },
    {
      title:"თიბისი უზი",
      img:require("../pictures/partnersslider/tbcuz.svg").default,
    },
    {
      title: "პროკრედიტ ბანკი",
      img: require("../pictures/partnersslider/procredit.svg").default,
    },
    {
      title:"კრედო",
      img:require("../pictures/partnersslider/credo.svg").default,
    },
    {
      title: "პაშა ბანკი",
      img: require("../pictures/partnersslider/pasha.svg").default,
    },
    {
      title:"თრასთ ბანკი",
      img:require("../pictures/partnersslider/trastbank.svg").default,
    },
    {
      title: "ტერა ბანკი",
      img: require("../pictures/partnersslider/tera.svg").default,
    },
    {
      title: "ეროვნული ბანკი",
      img: require("../pictures/partnersslider/erovnulibanki.svg").default,
    },
    {
      title: "სილქროუდ ბანკი",
      img: require("../pictures/partnersslider/silkroadbank.svg").default,
    },
    {
      title: "ინოტეკი",
      img: require("../pictures/partnersslider/innotec.svg").default,
    },
    {
      title:"მაგთი",
      img:require("../pictures/partnersslider/magti.svg").default,
    },
    {
      title: "კავკასუს ონლაინი",
      img: require("../pictures/partnersslider/caucasusonline.svg").default,
    },
    {
      title: "ორანჟი",
      img: require("../pictures/partnersslider/orange.svg").default,
    },
    {
      title: "ახალი ტელკო",
      img: require("../pictures/partnersslider/newtelco.svg").default,
    },
    {
      title: "სილქნეტი",
      img: require("../pictures/partnersslider/silknet.svg").default,
    },
  ]

  
  
  export const partnerslider = [
    {
      title: "ბაზის ბანკი",
      img: require("../pictures/partnersslider/bb.svg").default,
      titletwo:"საქართველოს ბანკი",
      imgtwo:require("../pictures/partnersslider/bog.svg").default,
    },
    {
      title: "კავკასუს ონლაინი",
      img: require("../pictures/partnersslider/caucasusonline.svg").default,
      titletwo:"ჰალიკ ბანკი",
      imgtwo:require("../pictures/partnersslider/halyk.svg").default,
    },
    {
      title: "ინოტეკი",
      img: require("../pictures/partnersslider/innotec.svg").default,
      titletwo:"ლიბერთი",
      imgtwo:require("../pictures/partnersslider/liberty.svg").default,
    },
    {
      title: "ნიუტელკო",
      img: require("../pictures/partnersslider/newtelco.svg").default,
      titletwo:"ორანჯი",
      imgtwo:require("../pictures/partnersslider/orange.svg").default,
    },
    {
      title: "პაშა ბანკი",
      img: require("../pictures/partnersslider/pasha.svg").default,
      titletwo:"პროკრედიტ ბანკი",
      imgtwo:require("../pictures/partnersslider/procredit.svg").default,
    },
    {
      title: "თიბისი",
      img: require("../pictures/partnersslider/tbc.svg").default,
      titletwo:"სილქროუდ ბანკი",
      imgtwo:require("../pictures/partnersslider/silkroadbank.svg").default,
    },
    {
      title: "სილქნეთი",
      img: require("../pictures/partnersslider/silknet.svg").default,
      titletwo:"ტერა",
      imgtwo:require("../pictures/partnersslider/tera.svg").default,
    },
    {
      title: "ვითიბი ბანკი",
      img: require("../pictures/partnersslider/vtb.svg").default,
      titletwo:"თრასთ ბანკი",
      imgtwo:require("../pictures/partnersslider/trastbank.svg").default,
    },
    {
      title: "ეროვნული ბანკი",
      img: require("../pictures/partnersslider/erovnulibanki.svg").default,
      titletwo:"მაგთი",
      imgtwo:require("../pictures/partnersslider/magti.svg").default,
    },
    {
      title: "დავრ ბანკი",
      img: require("../pictures/partnersslider/davrbank.svg").default,
      titletwo:"კრედო",
      imgtwo:require("../pictures/partnersslider/credo.svg").default,
    },
    {
      title: "ქართუ ბანკი",
      img: require("../pictures/partnersslider/cartu.svg").default,
      titletwo:"თიბისი უზი",
      imgtwo:require("../pictures/partnersslider/tbcuz.svg").default,
    },
  ]

  