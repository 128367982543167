

export const slider_data = [
    {
        id: "1",
        img: require('../pictures/slides/1.png'),
        title:'sliderTitle',
        description:`sliderDescription`
    },
    {
        id: "2",
        img: require('../pictures/slides/2.png'),
        title:'sliderTitleTwo',
        description:`sliderDescriptionTwo`,
    },
    {
        id: "3",
        img: require('../pictures/slides/3.png'),
        title:'sliderTitleThree',
        description:`sliderDescriptionThree`
    },
]