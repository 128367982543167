export const news_data = [
    {
        slug: "swift",
        nameing: "#SWIFT",
        color:'#4d90fe',
        title: "newstransguardtitle",
        description: "newstransguarddescription",
        fulldescription: "newstransguarddescriptionfull",
        button:'button',
        img: require('../pictures/news/news2.png')
    },
    {
        slug: "transguard",
        nameing: "#KYC",
        spanname:"#TRANSGUARD",
        color:'#35aa47',
        spancolor:'#c92e04',
        title: "newsswifttitle",
        description: "newsswiftdescription",
        fulldescription: "newsswiftdescriptionfull",
        button:'button',
        img: require('../pictures/news/news1.png')
    },
    {
        slug: "another",
        nameing: "another",
        title: "newskyctitle",
        color:'black',
        description: "newskycdescription",
        fulldescription: "newskycdescriptionfull",
        button:'button',
        img: require('../pictures/news/news3.png')
    },
    {
        slug: "kyc",
        nameing: "#KYC",
        color:'#35aa47',
        title: "newsaml",
        description: "newamlcdescription",
        fulldescription: "newamlcdescriptionfull",
        button:'button',
        img: require('../pictures/news/news4.png')
    },
]