import { Container, Row, Col } from "react-bootstrap";

import { NewsItem } from "../components";
// import NewsPaper from "../pictures/news.svg";
import { news_data } from "../data/newsdata";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
// import useFetch from "../hooks/useFetch";

const News = () => {
  const { t } = useTranslation();
  
  // let {loading, error, data} = useFetch('http://localhost:1337/api/newsitems?populate=*');
  // console.log(data.data)
  

  // if(loading) return <p>Loading.</p>
  // if(error) return <p>error </p>
  return (
    <div className="overflow-x-hidden-pages">
    <motion.div initial={{width:0}} animate={{width:'100%'}} exit={{x:window.innerWidth, transition:{transition:'0.1'}}}>
      <Container className="mt-34">
        {/* <Row>
          <Col className="d-flex jc-center">
            <img className="news-paper" src={NewsPaper} alt="NewsPaper" />
          </Col>
        </Row> */}
        <Row>
          <Col className="d-flex filtered">
            <ul>
              <li>
                <Link to="/news">{t('all')}</Link>
                <Link to="/news/swift">#SWIFT</Link>
                <Link to="/news/kyc">#KYC</Link>
                <Link to="/news/transguard">#TRANSGUARD</Link>
                <Link to="/news/another">{t('another')}</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="news-container">
          {news_data.map((item, key) => (
            <NewsItem key={key} item={item} />
          ))}
        </Row>
      </Container>
    </motion.div>
    </div>
  );
};

export default News;
