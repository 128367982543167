import { useState } from "react";
import { Modal, Col, CloseButton } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const Servicesitem = ({ item }) => {
  const [show, setShow] = useState(false);

  
  const { t } = useTranslation();
  return (
    <>
      
      <Col  xxl={4} xl={6} lg={6}  className="mt-24 " key={item.id}>
        <div className="news-box shadow-for-container">
          <div className="news-img">
            <img
              className="new-img"
              src={item.img}
              alt="pic"
            />
          </div>
          <h2>{t(item.title)}</h2>
          <p>{t(item.description)}</p>
          <Col className="d-flex jc-end plr-13 news-button-container">
          <p style={{ color: item.color, fontWeight:600, padding:0 }}>{item.nameing} <span style={{color:item.spancolor, fontWeight:600, marginLeft:6}}>{item.spanname}</span></p>
          <button onClick={() => setShow(true)}>{t(item.button)}</button>
          </Col>
        </div>
      </Col>
      

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header className="modal-wrapper">
        <CloseButton variant='white' onClick={() => setShow(false)}></CloseButton>
          <Modal.Title className="modal-title title-font-size">{t(item.title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-description">
          <p className="text-size">
            <div dangerouslySetInnerHTML={{ __html: t(item.fulldescription) }} />
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Servicesitem;
