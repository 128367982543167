import { Col, Row, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { partners } from "../data";

import { useTranslation } from "react-i18next";

const Customersandclients = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
      >
        <Container className="mt-55">
        <h1 className="d-flex jc-center txt-center">{t("customresandclients")}</h1>
      </Container>
        <Container>
          <Row className="mt-55 jc-center-media-992">
            {partners.map((item, key) => (
              <Col xxl={4} xl={6} md={12} xs={1} key={key} className="imgdouble">
                <img src={item.img}></img>
              </Col>
            ))}
          </Row>
        </Container>
      </motion.div>
    </div>
  );
};

export default Customersandclients;
