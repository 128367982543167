import { Container, Row, Col } from "react-bootstrap";
import Mail from "../../pictures/mail.svg";
import Phone from "../../pictures/phone.svg";
import Pin from "../../pictures/pin.svg";
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t, i18n } = useTranslation();

  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <Container className="mt-55" id="footer">
        <Row>
          <Col className="p-0 d-flex jc-center">
            <div className="contact-wrapper shadow-for-container">
              <h2 className="title-font-size">{t('contactinformation')}</h2>
              <div className="text-wrapper mt-30">
                <p>
                  <img src={Pin} alt="pin" /> {t('contactaddress')}
                </p>
                <p>
                  <img src={Phone} alt="phone" /> (+995 32) 2 43 03 03
                </p>
                <p>
                  <img src={Mail} alt="mail" /> sb@gftc.ge
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="footer">
        <Container>
          <Row>
            <Col className="d-flex jc-center p-0">
              <p>{t('rights')}</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default Footer