import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './core/App';
import { BrowserRouter } from 'react-router-dom'
import Translate from '../src/core/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

