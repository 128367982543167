import { Routes, Route, useLocation } from "react-router-dom";
import {
  Main,
  Swift,
  Transguard,
  News,
  Filterednews,
  About,
  Contact,
  Customersandclients,
  // Uploadpage
} from "../../pages";
import { AnimatePresence } from "framer-motion";
import {} from '../../pages/Contact'

const AnimatedRoutes = ({ overley, setOverley, hidden, setHidden }) => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes>
          <Route
            path="/"
            element={<Main overley={overley} setOverley={setOverley} />}
            location={location}
            key={location.pathname}
          />
          <Route path="/about" element={<About />} />
          <Route path="/swift" element={<Swift />} />
          <Route path="/transguard" element={<Transguard />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:slug" element={<Filterednews />} />
          <Route path="/customersandclients" element={<Customersandclients />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/upload" element={<Uploadpage />}/> */}
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default AnimatedRoutes;
