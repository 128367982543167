export const single_services = [
    {
        title: "swiftref",
        link:"swift",
        description: "swiftdescription",
        img: require('../pictures/swiftref.svg').default
    },
    {
        title: "transguardservice",
        link:"transguard",
        description: "transguarddescription",
        img: require('../pictures/transguard.svg').default
    },
]