import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NewsItem } from "../components";
import { news_data } from "../data/newsdata";
// import NewsPaper from "../pictures/news.svg";
import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
// import useFetch from "../hooks/useFetch";

const Filterednews = () => {
  const { slug } = useParams();
  console.log(slug)
  // let {loading, error, data} = useFetch('http://localhost:1337/api/newsitems?populate=*');
  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
      >
        <Container className="mt-34">
          {/* <Row >
                    <Col className='d-flex jc-center'>
                        <img className='news-paper' src={NewsPaper} alt="NewsPaper" />
                    </Col>
                </Row> */}
          <Row>
            <Col className="d-flex filtered">
              <ul>
                <li>
                  <Link to="/news">#ყველა</Link>
                  <Link to="/news/swift">#SWIFT</Link>
                  <Link to="/news/kyc">#KYC</Link>
                  <Link to="/news/transguard">#TRANSGUARD</Link>
                  <Link to="/news/another">#სხვა</Link>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="mt-24 padding-02">
            {news_data.filter((e) => e.slug === slug)
              .map((item, key) => (
                <NewsItem key={key} item={item} />
              ))}
          </Row>
        </Container>
      </motion.div>
    </div>
  );
};

export default Filterednews;
