import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from 'react-bootstrap';
import { Footer, Navbar, AnimatedRoutes } from '../components';
import { motion } from 'framer-motion';
import '../styles/app.scss';

function App() {
  const [overley, setOverley] = useState(false);
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);
  const [swiched, setSwiched] = useState(false);

  const variants = {
    swiched:{ opacity: 0, scale: 0.5, duration: 0.5 },
    setSwiched:{ opacity: 1, scale: 1, duration: 0.5 },
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  return (
    <ThemeProvider>
      <motion.div animate={swiched ? 'swiched' : 'setSwiched'} variants={variants}

      // onClick={() => !hidden ? setHidden(true) : hidden}
      >
        <Navbar swiched={swiched} setSwiched={setSwiched} overley={overley} setOverley={setOverley} hidden={hidden} setHidden={setHidden} />
        <AnimatedRoutes hidden={hidden} setHidden={setHidden} overley={overley} setOverley={setOverley} />
        <Footer />
        </motion.div>
    </ThemeProvider>
  );
}

export default App;
