import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const today = new Date();
  const year = today.getFullYear();
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {

  

  geo: {
    translation: {
      // მენიუ
      home: "მთავარი",
      about: "ჩვენ შესახებ",
      another:'#სხვა',
      all:'#ყველა',
      news: "სიახლეები",
      services: "სერვისები",
      transguard: "ტრანსგარდი",
      swift: "სვიფტის სერვის ბიურო",
      contact: "კონტაქტი",
      casemanager: "ქეის მენეჯერი",
      statement: "განაცხადი",
      customresandclients: 'მომხმარებლები და პარტნიორები',
      aboutcompany:'კომპანიის შესახებ',
      // განაცხადის ფორმა
      companydata: "კომპანიის მონაცემები",
      companyname: "კომპანიის დასახელება",
      country: "ქვეყანა",
      address: "მისამართი",
      identificationcode: "საიდენტიფიკაციო კოდი",
      cityregion: "ქალაქი/რეგიონი",
      officenumber: "ოფისის ტელეფონი",
      personaldata: "პერსონალური მონაცემები",
      name: "სახელი",
      lastname: "გვარი",
      lastname: "გვარი",
      officialposition: "სამსახურეობრივი პოზიცია",
      email: "ელ-ფოსტა",
      send: "გაგზავნა",
      //პროდუქტები
      products: "პროდუქტები",
      //ჩვენს შესახებ ინფორმაცია
      aboutdescription: `
      შპს „ჯი-ეფ-თი-სი“ 2008 წელს დაარსდა  და კავკასიაში ერთადერთ სრულფასოვან „სვიფტის სერვის ბიუროს“ წარმოადგენს.
      
      ხსენებული სტატუსის შესანარჩუნებლად, კომპანია ფინანსური თუ ტექნიკური აუდიტის პროცესს რეგულარულად გადის,
      
      რასაც სერტიფიცირებულ სერვის ბიუროთა <a target="_blank" href="https://www.swift.com/about-us/partner-programme/shared-infrastructure-programme/service-bureau-directory">ჩამონათვალში</a> მისი არსებობა ადასტურებს.
      `,
      aboutdescriptionone: ` შპს „ჯი-ეფ-თი-სი“ 2008 წელს დაარსდა და კავკასიაში ერთადერთ სრულფასოვან „სვიფტის სერვის ბიუროს“ წარმოადგენს. ხსენებული სტატუსის შესანარჩუნებლად, კომპანია ფინანსური თუ ტექნიკური აუდიტის პროცესს რეგულარულად გადის, რასაც სერტიფიცირებულ სერვის ბიუროთა <a target="_blank" href="https://www.swift.com/about-us/partner-programme/shared-infrastructure-programme/service-bureau-directory">ჩამონათვალში</a> მისი არსებობა ადასტურებს.
      
      „ჯი-ეფ-თი-სი“-ის პროდუქტები როგორც ადგილობრივ, ასევე ცენტრალური აზიის ბაზარზე მაღალი ავტორიტეტით სარგებლობენ. ამას ადასტურებს ისიც, რომ საქართველოში რეგისტრირებულ ბანკთა აბსოლუტურ უმრავლესობას, მათ შორის „საქართველოს ეროვნულ ბანკს“, სერვის ბიუროსთან მჭიდრო თანამშრომლობის ხანგრძლივი ისტორია აკავშირებს.
      `,
      aboutdescriptiontwo: ` „ჯი-ეფ-თი-სი“ მჭიდროდ თანამშრომლობს სხვა, შედარებით მცირე ფინანსურ ორგანიზაციებთანაც, რომელთაც მოქნილ და მათზე მორგებულ მომსახურებას სთავაზობს.

      2013 წელს „ჯი-ეფ-თი-სი“-ის შვილობილი კომპანია - „ჯი-ბი-თი-სი“ დაარსდა, რომლის საქმიანობაც AML / KYC შესაბამისობის სისტემების შექმნა-განვითარებისკენაა მიმართული. „ჯი-ბი-თი-სი“-ის მთავარ პარტნიორს, საერთაშორისო ფინანსური მონაცემების ლიდერი, „რეფინიტივი“ წარმოადგენს.  კომპანიის ძირითადი პროდუქტი - „ტრანსგარდი“ მუდმივად ვითარდება, რასაც წლების მანძილზე მომხმარებელთა რიცხვის მზარდი ტენდენცია ადასტურებს.
      
      2016 წელს „ნაციონალური ბიზნეს-რეიტინგების კავშირის“ მიერ „ჯი-ეფ-თი-სი“ ფინანსურ-სამეურნეო დარგის ლიდერად იქნა აღიარებული და ოქროს მედალი მოიპოვა.
      
      ჩვენი მიზანია - მივაწოდოთ ფინანსურ ორგანიზაციებს მომხახურების სრული სპექტრი საბანკო ტექნოლოგიების სფეროში.
      
      `,
      aboutdescriptionthree: ` ჩვენი კრედო - ვიყოთ ლიდერები საფინანსო ინსტრუმენტების სფეროში; მივაწოდოთ მომხმარებელს უმაღლესი ხარისხის, მათზე მორგებული სერვისი; შევქმნათ საუკეთესო სამუშაო გარემო ჩვენი თანამშრომლებისთვის.

      სამეთვალყურეო საბჭოს წევრები:

      •	თავმჯდომარე - თეონა ესიაშვილი

      •	თავმჯდოამრის მოადგილე - ნინო ალხაზაშვილი

      •	წევრი - ნიკოლოზ თუთბერიძე
      `,
      //გამოყოფილი სერვისები
      'swiftref': 'სვიფტის სერვის ბიურო',
      'swiftdescription': 'ფინანსური მონაცემთა ცნობარი',
      'transguardservice': 'ტრანსგარდი',
      'transguarddescription': 'ტრანზაქციების მონიტორინგი',
      //სიახლეები
      news:'სიახლეები',
      'newstransguardtitle':`SIBOS 2022
      
      `,
      'newstransguarddescription':"პანდემიით გამოწვეული პაუზის შემდეგ, 2022 წელს პირველი SIBOS გაიმართა. ამსტერდამში ჩატარებულ ღონისძიებას 10 000-ზე მეტი დელეგადი დაესწრო. კონფერენციაში საქართველოს წარმომადგენლები სვიფტის სერვის.....",
      'newstransguarddescriptionfull':`პანდემიით გამოწვეული პაუზის შემდეგ, 2022 წელს პირველი SIBOS გაიმართა. ამსტერდამში ჩატარებულ ღონისძიებას 10 000-ზე მეტი დელეგატი დაესწრო. კონფერენციაში საქართველოს წარმომადგენლები სვიფტის სერვის ბიუროსა და ეროვნული ბანკიდანაც იღებდნენ მონაწილეობას.
      </br></br>
      წლევანდელ ღონისძიებაზე ძირითადი აქცენტები შემდეგი მიმართულებით გაკეთდა:
      </br></br>
      •	CBDC და ტოკენიზირებული აქტივები
      </br></br>
      •	მცირე მოცულობის საერთაშორისო გადახდების/გადარიცხვების სწრაფი ზრდის ტემპი;
      </br></br>
      •	ოპერაციების მაქსიმალური გამარტივება, Pre-validation, Case management და Payment Control პროდუქტების მეშვეობით
      </br></br>
      •	გამჭვირვალობის ზრდით საშუამავლო ტარიფების ოპტიმიზაცია
      </br></br>
      •	ISO2022 და მისი მნიშვნელოვანი უპირატესობები MT ფოტმატთან მიმართებაში
      </br></br>
      •	„სვიფტის“ ტრანსოფმაცია მონაცემთა ეფექტურობაზე ოერიენტირებულ ინოვაციურ პლატფორმად 
      </br></br>
      •	ფინანსური დანაშაული და მის წინააღმდეგ ერთობლივი ბრძოლის საჭიროება.
      `,
      button:'ვრცლად',
      'newsswifttitle':"სანქციები და რეგულაციები დაჩქარებული ოპერაციების პირობებში",
      'newsswiftdescription':"ფინანსური დანაშაულის კომპლექსურობა, უსაფრთხოების ინსტრუმენტების განვითარებასთან ერთად იზრდება. საეჭვო ოპერაციების გამოვლენას, ქვეყნებს შორის განსხვავებული რეგულაციები და ტრანზაქციათა მოძრაობის.....",
      'newsswiftdescriptionfull':`ფინანსური დანაშაულის კომპლექსურობა, უსაფრთხოების ინსტრუმენტების განვითარებასთან ერთად იზრდება. საეჭვო ოპერაციების გამოვლენას, ქვეყნებს შორის განსხვავებული რეგულაციები და ტრანზაქციათა მოძრაობის დაჩქარებული ტემპი კიდევ მეტად ართულებს.
      </br></br>
      გამოწვევებზე საპასუხოდ, რეგიონებისთვის დამახასიათებელი სპეციფიური კანონმდებლობისა და სანქცირებულთა სიების გამოყენება ხდება საჭირო. ამასთან ერთად, მაღალი ტემპისა და მომსახურების მზარდი სტანდარტის ფონზე, ოპერაციათა გადამოწმება დიდ ძალისხმევასთანდაა დაკავშირებული.
      </br></br>
      ხსენებულ რისკებთან და მონაცმეთა ნაკადებთან გამკლავება, მხოლოდ ადამიანური რესურსით, მექანიკურად შეუძლებელია. სწორედ ამიტომ, შესაბამისობის ავტომატიზებული სისტემები ფინანსური ორგანიზაციების საქმიანობის განუყოფელი ნაწილი გახდა.
      </br></br>
      თანამედროვე პროგრამული უზრუნველყოფა, ისეთი, როგორიცაა „ტრანსგარდი“,, საერთაშორისო სიებზე და ხელოვნურ ინტელექტზე დაყრნობით, თითოეული ოპერაციისა თუ კლიენტის პროფილის გადამოწმების საშუალებას მილიწამებში იძლევა. მსგავსი ავტომატიზებული პროცედურა მრავალმხრივობითა და მაღალი სიზუსტით ხასიათდება.
      </br></br>
      ფინანსური დანაშაული მუდმივად ვითარდება, თუმცა ავტორიტეტული შესაბამისობის სისტემის გამოყენება რისკებს მინუმამდე ამცირებს
      .`,
      button:'ვრცლად',
      'newskyctitle':`ადამიანები ბიზნესის უკან 
      
      `,
      'newskycdescription':"15 დეკემბერს სასტუმრო „ბილტმორში“ გაყიდვების ასოციაციის გამოფენა – „ადამიანები ბიზნესის უკან“ გაიმართა, სადაც 50-მდე კომპანიამ, მათ შორის „ჯი-ეფ-თი-სი“-მ მიიღო მონაწილეობა.........",
      'newskycdescriptionfull':`15 დეკემბერს სასტუმრო „ბილტმორში“ გაყიდვების ასოციაციის გამოფენა – „ადამიანები ბიზნესის უკან“ გაიმართა, სადაც 50-მდე კომპანიამ, მათ შორის „ჯი-ეფ-თი-სი“-მ მიიღო მონაწილეობა.
      </br></br>
      გამოფენის სტუმრებს შესაძლებლობა ქონდათ, შეხვედროდნენ მონაწილე ორგანზიაციების მენეჯერებს, გაცნობოდნენ მათ სერვისებს, პროდუქტებს, ან უბრალოდ გაეზიარებინათ გამოცდილება.
      </br></br>
      ღონისძიების ფარგლებში, ფუქნციონირებდა სპეციალური სივრცე B2B შეხვედრებისა და მოლაპარაკებებისთვის.
      </br></br>
      გამოფენაზე მოსულ სტუმრებს „ჯი-ეფ-თი-სი“-ის სტენდთან, ბრენდირებული სუვენირები გადაეცათ.
      `,
      button:'ვრცლად',
      'newsaml':'AML / KYC გამოწვევები - შესაძლებლობა ციფრული ბანკებისთვის',
      'newamlcdescription':`ხარჯების შემცირებითა და სერვისის გამარტივებით, ციფრული ბანკები მუდმივად ცდილობენ, საკუთარი საქმიანობა მომხმარებლისთვის მეტად მიმზიდველი გახადონ.....`,
      'newamlcdescriptionfull':`ხარჯების შემცირებითა და სერვისის გამარტივებით, ციფრული ბანკები მუდმივად ცდილობენ, საკუთარი საქმიანობა მომხმარებლისთვის მეტად მიმზიდველი გახადონ.
      </br></br>
      უკანასკნელი წლების კოვიდ-პანდემიამ, კიდევ უფრო თვალსაჩინო გახადა ციფრული ტრანსფორმაციის აუცილებლობა და შესაძლებლობები.
      </br></br>
      დიჯიტალიზაცია დამატებით შესაძლებლობებს ქმნის, როგორც მომხმარებლებისთვის, ასევე კიბერ დანაშაულისთვის.
      </br></br>
      2021 წელს, „ფულის რეცხვის“ წინააღმდეგ მსოფლიო მასშტაბით დახარჯული რეკორდული 50 მილიარდი აშშ დოლარის მიუხედავად, ფინანსურ ორგანზიაციებს 10 მილიარდ აშშ დოლარზე მეტის გადახდა ჯარიმებში მოუწიათ. ეს ნიშნავს, რომ არსებული AML სისტემების მაქსიმალურად ეფექტურად გამოყენება ვერ ხერხდება. პარალელურად, „რეფინიტივის“ მიერ 2021 წელს ჩატარებული გამოკითხვის თანახმად, რესპონდენტთა 86% თვლის, რომ თანამედროვე ეტქნოლოგიები მათ ფინანსური დანაშაულის გამოვლენაში მნიშვნელოვნად ეხმარება.
      </br></br>
      რისკების შემცირების მიზნით, აუცილებელია თითოეული ტრანზაქცია თუ დოკუმენტი, შემდეგი ასპექტებით გადამოწმდეს:
      </br></br>
      -	დისტანციური მომსახურბეის შემთხვევაში, კლიენტის ნამდვილობისა და ავთენტურობის გადამოწმება (Liveness/Facial check)
      </br></br>
      -	კლიენტის (ტრანზაქციის მონაწილე) შავ და პოლიტ. აქტიურ პირთა სიებთან გადამოწმება
      </br></br>
      -	მათი წარსულის მედია-წყაროებთან გადამოწმება
      </br></br>
      -	კლიენტის შესაბამისი, მკაფიო რისკიანობის ქულის გამოთვლა
      სამუშაოს კომპლექსურობიდან გამომდინარე, ისმის კითხვა - როგორ შეუძლია ფინანსურ ინსტიტუტს, ფოკუსი გააკეთოს საკუთარ ბიზნესზე და მომხმარებელზე ისე, რომ მაქსიმალურად დააკმაყოფილოს ყველა AML რეგულაცია? ამ შეკითხვაზე პასუხი პროცესის ავტომატიზაცია, სწორი ინფორმაციისა და ტექნოლოგიის შერჩევაა. გაზრდილი საინფორმაციო ნაკადების ფონზე, მონაცემთა ციფრულ დამუშავებას ალტერნატივა არ აქვს. მცირე ინსტიტუტებშიც კი, სრულყოფილი ბაზებისა და ხელოვნური ინტელექტის გამოყენებას მნიშვნელოვანი დროითი თუ ფინანსური დანაზოგის გაკეთება შეუძლია.
      ზემოთ თქმულიდან გამომდინარე, ფინანსური სექტორის მომავალი განვითარება მნიშვნელოვნადაა დაკავშირებული ციდფრულ ტრანსფორმაციასთან.
      `,
      allnews:'ყველა სიახლე',
      //კონტაქტები ქვედა ნავიგაციის ბარი
      contactinformation:'საკონტაქტო ინფორმაცია',
      contactaddress:'თბილისი 0162, ჭავჭავაძის 37ბ',
      rights:`ყველა უფლება დაცულია © შპს ${year} "საქართველოს საფინანსო სატელეკომუნიკაციო კომპანია ჯი-ეფ-თი-სი"`,
      //დროშა
      flag: require('../pictures/united-kingdom.svg').default,
      //კლიენტები და მომხმარებლები
      customers:'მომხმარებლები',
      partners:'პარტნიორები',
      sliderTitle:`2022 წლის შეჯამება`,
      sliderDescription:`800+ გადაწყვეტილი პრობლემა 
      150+ განახლება`,
      sliderTitleTwo:`ISO20022`,
      sliderDescriptionTwo:`2023 წლის მარტიდან MX ფორმატის გამოყენება 
      სავალდებულოა. მზად ხართ ცვლილებისთვის?`,
      sliderTitleThree:`სრულად ახალი BLS`,
      sliderDescriptionThree:`დამთხვევის გაუმჯობესებული სიზუსტე, 
      გაზრდილი უსაფრთხოება, დახვეწილი ინტერფეისი...`,
      serviceSwift:'სვიფტის სერვის ბიურო',
      serviceDescription:'„სვიფტი“ მსოფლიო მასშტაბის ქსელია, რომელსაც ბანკები და სხვა ორგანიზაციები ფინანსური ინფორმაციის სწრაფად და უსაფრთხოდ გასაცვლელად იყენებენ. „სვიფტის“ შეტყობინებებზე დაყრდნობით ფასიანი ქაღალდების, სახაზინო, გადარიცხვების და მრავალი სხვა ტიპის ოპერაცია ხორციელდება.',
      swiftComunication:`„სვიფტის“ სარეზერვო კომუნიკაცია
      
      `,
      swiftComunicationDescription:"„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა.....",
      swiftComunicationFullDescription:`„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა გააჩნია, რომლის ყველაზე კომპლექსურ ნაწილს საკომუნიკაციო კომპონენტები წარმოადგენენ.
      </br></br>
      ფინანსური ოპერაციების შესრულების სისწრაფე და ხარისხი კავშირის სტაბილურობაზეა დამყარებული. „სვიფტის“ საკომუნიკაციო აპარატურა დახურული ინტერფეისებითა და განსაკუთრებული სირთულით გამოირჩევა, ამიტომ ავარიული შემთხვევის აღმოფხვრა მნიშვნელოვან დროით და ფინანსურ დანახარჯებთანაა დაკავშირებული.
      </br></br>
      რისკების შემცირების მიზნით, „ჯი-ეფ-თი-სი“ „სვიფტის“ წევრ ორგანიზაციებს სარეზერვო კომუნიკაციის სერვისს თავაზობს. სარეზერვო კომუნიკაციით სარგებლობის შემთხვევაში, მომხმარებელს აქვს საშუალება, ყოველდღიური საქმიანობა საკუთარი ინფრასტრუქტურის გამოყენებით აწარმოოს, თუმცა ქსელში პრობლემის არსებობის შემთხვევაში,
       „სვიფტის“ სერვისებთან კავშირი „ჯი-ეფ-თი-სი“-ის სარეზერვო ინფრასტრუქტურის გავლით გააგრძელოს.`,
      
       swiftComunicationInfrastructure:'„სვიფტის“ საკომუნიკაციო ინფრასტრუქტურა',
       swiftComunicationInfrastructureDescription:"„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა.....",
       swiftComunicationInfrastructureDescriptionFull:`„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა გააჩნია, რომლის ყველაზე კომპლექსურ ნაწილს საკომუნიკაციო კომპონენტები წარმოადგენენ.
       </br></br>
       ფინანსური ოპერაციების შესრულების სისწრაფე და ხარისხი კავშირის სტაბილურობაზეა დამყარებული.  „სვიფტის“ საკომუნიკაციო აპარატურა დახურული ინტერფეისებითა და განსაკუთრებული სირთულით გამოირჩევა, ამიტომ ავარიული შემთხვევის აღმოფხვრა მნიშვნელოვან დროით და ფინანსურ დანახარჯებთანაა დაკავშირებული.
       </br></br>
       რისკების მინიმიზაციისა და საიმედოობის ზრდის მიზნით, „ჯი-ეფ-თი-სი“ „სვიფტის“ წევრ ორგანზიაციებს „სვიფტის სერვის ბიუროს“ ბაზაზე არსებული საკომუნიკაციო ინფრასტრუქტურის გამოყენებას თავაზობს.
       </br></br>
       სერვის ბიუროს საკომუნიკაციო ინფრასტრუქტურით სარგებლობის შემთხვევაში, მომხმარებელი „სვიფტის“ ლიცენზიების, აპარატურის, არაპროფილური თანამშრომლების, რისკებისა და ხარჯებისგან თავისუფლდება.
       </br></br>
       გამარტივებულ პროცესებთან და შემცირებულ ხარჯთან ერთად, მომხარებელი „ჯი-ეფ-თი-სი“-ის სრულად დუბლირებულ ინფრასტრუქტურას, უმაღლესი ხარისხის მხარდაჭერასა და რეგულარულ განახლებებს იღებს. 
       `,
       swiftFullInfrastructure:`„სვიფტის“ სრული ინფრასტრუქტურა
        
       `,
       swiftFullInfrastructureDescription:'„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა.....',
       swiftFullInfrastructureDescriptionFull:`„სვიფტში“ ჩართულ ყველა ორგანიზაციას მრავალფეროვანი და ძვირადღირებული ტექნოლოგიური ინფრასტრუქტურა გააჩნია. ფინანსური საქმიანობის ეფექტურობა სწორედ მის გამართულ ფუნქციონირებაზეა დამოკიდებული.
       </br></br>
       „სვიფტის“აპარატურა დახურული ინტერფეისებითა და განსაკუთრებული სირთულით გამოირჩევა, ამიტომ მისი ყოველდღიური მართვა, განახლება და კონფიგურაცია მნიშვნელოვან სირთულეებთანაა დაკავშირებული. სიტუაციას გახშირებული სავალდებულო განახლებებიც ართულებს, რომელთა ინსტალაცია, წელიწადში მინიმუმ შვიდჯერაა საჭირო.
       </br></br>
       ინფრასტრუქტურის მომსახურება, იშვიათი კვალიფიკაციის მქონდე პერსონალის გარდა, საკმაოდ დიდ სერვერულ და ქსელურ რესურსს მოითხოვს. რეპლიკაციის შემთხვევაში, მოთხოვნები ორმაგდება. ეს ყველაფერი, თავის მხრივ, სოლიდურ ფინანსურ დანახარჯებს უკავშირდება.
       </br></br>
       ხარჯების შემცირებისა და საიმედოობის მაქსიმიზაციის მიზნით, „ჯი-ეფ-თი-სი“ მომხმარებლებს „სვიფტის“ საკუთარი  ინფრასტრუქტურის გამოყენებას სთავაზობს. სერვისით სარგებლობის შემთხვევაში, „სვიფტთან“ სამუშაოდ, მხოლოდ პერსონალური კომპიუტერი ან ტერმინალური სერვერია საჭირო, ყველაფერი დანარჩენი კი „ჯი-ეფ-თი-სი“-ის სრულად დუბლირებულ მონაცემთა ცენტრებშია განლაგებული.
       </br></br>
       სხვა-სერვის ბიუროებისგან განსხვავებით, „ჯი-ეფ-თი-სი“-ის ინფრასტრუქტურა გამორჩეულია და თითოეულ მომხმარებელს ინდივიდუალურ, სრულად იზოლირებულ, უსაფრთხო სერვერულ გარემოს სთავაზობს.
       </br></br>
       სერვის-ბიუროს სრული ინფრასტრუქტურით სარგებლობა მომხარებელს სრულად ათავისუფლებს „სვიფტის“ ლიცენზიების, განახლებების, ბექაფირებისა და საკომუნიკაციო ხარჯებისგან. დანახარჯების შემცირებასთადნ ერთად, სერვისი მაღალ ხარისხიან, პერსონალიზებულ მხარდაჭერას მოიცავს.
       `,
       transguardDescription:'სისტემა „ტრანსგარდი“ AML და KYC შესაბამისობის პროგრამულ უზრუნველყოფას წარმოადგენს. აღნიშული პროდუქტი არსებობის და განვითარების 10 წელზე მეტს ითვლის. დღევანდელი მდგომარეობით, ის საქართველოს ფინანსური ტექნოლოგიების სექტორის ერთ-ერთი ლიდერია.',
       blsBlackList: `BLS - შავ სიებთან შედარება
        
       `,
       blsBlackListDescripton:"„ბი-ელ-ეს“ ალგორითმების ერთობლიობას წარმოადგენს, რომლის მიზანი ტრანზაქციებისა თუ კლიენტების სკანირება და სხვადასხვა ტიპის სიებთან შედარებაა.....",
       blsBlackListDescriptionFull:`„ბი-ელ-ეს“ ალგორითმების ერთობლიობას წარმოადგენს, რომლის მიზანი ტრანზაქციებისა თუ კლიენტების სკანირება და სხვადასხვა ტიპის სიებთან შედარებაა. 
       </br></br>
       სერვისის ფარგლებში, სიების კუთხით, ბაზრის საერთაშორისო ლიდერი კომპანიის „რეფინიტივის“ („ლონდონის საფონდო ბირჟის ჯგუფი“) სიებია (ყოფილი Thomson Reuters, World-Check) გამოყენებული. 
       </br></br>
       პროდუქტი, 1000-ზე მეტ სანქცირებულ, პოლიტიკურად აქტიურ პირთა და სხვა ტიპის სიას მოიცავს. სიების წყაროებს, როგორც ავტორიტეტული საერთაშორისო ორგანიზაციები, ისე სხვადასხვა ქვეყნები, მათ შორის საქართველოც წარმოადგემს.  აღნიშული სიების განახლება, მისი კრიტიკულობიდან გამომდინარე, ავტომატურად და დღეში ორჯერ ხორციელდება.
       </br></br>
       სერვისს ორი დამოუკიდებელი რეჟიმი გააჩნია:
       </br></br>
       •	ავტომატური - სისტემა მომხმარებლის ან მესამე მხარის ინფრასტრუქტურაში ინერგება და მისი ინტეგრაცია დამკვეთის ძირითად სისტემასთან კეთდება. აღნიშნული სქემის ფარგლებში ნებისმიერი ჩანაწერის სკანირება (კლიენტის ონბორდინგი, ნებისმიერი ტიპის ოპერაცია ან გარიგება) რეალურ დროში და ავტომატურდ რეჟიმში ხდება. სისტემა ასევე დამკვეთის კლიენტთა სრული ბაზის ყოველდღიურ სკანირებას უზრუნველყოფს.
       </br></br>
       •	მექანიკური - (ე.წ. ონლაინ იუზერი) აღნიშული პაკეტის შემთხვევაში ერთიანი სისტემა დანერგილია სერვის ბიუროს მხარეს და მომხმარებელს სერვისზე წვდომა მოშორებულად, ვებ-გვერდის საშუალებით გააჩნია. სიებში კლიენტების გადამოწმება და სიებთან შეედარება მონაცემების მექანიკურად შეყვანის საფუძველზე ხორციელდება.
       `,
       fmrReports:`FMR - ფმს რეპორტინგი
        
       `,
       fmrDescription:"„ეფ-ემ-ერ“-ის მიზანს საქართველოს ფინანსური მონიტორინგის სამსახურის მიერ მოთხოვნილი, საეჭვო და ზღვარს ზემოთ ოპერაციების/გარიგებების.....",
       fmrDescriptionFull:"„ეფ-ემ-ერ“-ის მიზანს საქართველოს ფინანსური მონიტორინგის სამსახურის მიერ მოთხოვნილი, საეჭვო და ზღვარს ზემოთ ოპერაციების/გარიგებების ავტომატური გამოვლენა წარმოადგენს. სისტემა დამკვეთის ან მესამე მხარის ინფრასტრუქტურაში ინერგება და მის ძირითად საოპერაციო სისტემასთან ინტეგრირდება. „ეფ-ემ-ერ“ინფორმაციას შესრულებული ოპერაციებისა თუ გარიგებების შესახებ, 24 საათის განმავლობაში აგროვებს და დღის ბოლოს მათ ანალიტიკას ერთიანად ახდენს. პროცესის დასასრულს სერვისი ავტომატურად აგენერირებს ფმს-ის ანაგრიშგების ფორმას. სისტემა ფმს-ის ინფრასტრუქტურასთანაა ინტეგრირებული, რაც უკვე მომზადებული ანგარიშგების ფორმის მარტივად, ერთი კლიკით გაგზავნის საშუალებას იძლევა.",
       kycCustomerRisk:"KYC - მომხმარებელთა რისკიანობის შეფასება",
       kycCustomerRiskDescription:'სისტემა ადგილობრივი და საერთაშორისო სტანდარტების საფუძველზეა შექმნილი და განსხვავებული კლასობრივი ალგორითმები მასში მაქსიმალურადაა ათვისებული.....',
       kycCustomerRiskDescriptionFull:'სისტემა ადგილობრივი და საერთაშორისო სტანდარტების საფუძველზეა შექმნილი და განსხვავებული კლასობრივი ალგორითმები მასში მაქსიმალურადაა ათვისებული. KYC მომხმარებლის მხარეს ინერგება  და მისი ინტეგრირება ძირითად საოპერაციო სისტემასთან ხორციელდება. სისტემის მიზანს კლიენტების ბაზების მართვა, კონკრეტულად კი რისკიანობის შეფასება წარმოადგენს. რისკიანობის ანალიზი ინდივიდუალური და საერთაშორისო მიდგომების საფუძველზე ხორციელდება. შესრულებული ტრანზაქციების ანალიტიკა უკვე შესრულებული, ისტორიული  ტრანზაქციების საფუძველზე წარმოებს.',
      },
  },
  en: {
    translation: {
      // Menu
      home: "Home",
      about: "About Us",
      news: "News",
      all:'#All',
      another:'#Other',
      services: "Services",
      transguard: "TransGuard",
      swift: "SWIFT Service Bureau",
      contact: "Contact",
      casemanager: "Case Manager",
      statement: "Statement",
      customresandclients: 'Customers and Partners',
      aboutcompany:'About the company',
      // Statement Form
      companydata: "Company Data",
      companyname: "Company Name",
      country: "Country",
      address: "Address",
      identificationcode: "Identification Code",
      cityregion: "City/Region",
      officenumber: "Office phone",
      personaldata: "Personal information",
      name: "First name",
      lastname: "Last name",
      officialposition: "Official position",
      email: "E-mail",
      send: "Send",
      //Products
      products: "Products",
      //About Information
      aboutdescription: `“GFTC” LLC was established in 2008 and is the only full-fledged “SWIFT Service Bureau” in the Caucasus.
      
      To maintain the mentioned status, the company regularly goes through the process of financial or technical audit, which is confirmed by its presence <a target="_blank" href="https://www.swift.com/about-us/partner-programme/shared-infrastructure-programme/service-bureau-directory">in the list</a> of certified service bureaus.      
      `,
      aboutdescriptionone: ` “GFTC” LLC was established in 2008 and is the only full-fledged “SWIFT Service Bureau” in the Caucasus. To maintain the mentioned status, the company regularly goes through the process of financial or technical audit, which is confirmed by its presence <a target="_blank" href="https://www.swift.com/about-us/partner-programme/shared-infrastructure-programme/service-bureau-directory">in the list</a> of certified service bureaus.
      `,
      aboutdescriptiontwo: ` Products of “GFTC” enjoy a high reputation both in the local market and in the Central Asian market. This is confirmed by the fact that the vast majority of banks registered in Georgia, including the National Bank of Georgia, have a long history of close cooperation with the Service Bureau.
      
      “GFTC” cooperates with other, relatively small financial organizations, offering them flexible and customized services. 
      
      In 2013, “GBTC”, a subsidiary company of “GFTC”, was established, activities of which are aimed at creation and development of AML / KYC compliance systems. The main partner of “GBTC” is “Refinitiv”, the leader in international financial data. The company’s main product, “Transguard”, is constantly developing, as evidenced by the increasing trend of the number of customers over the years.
      
      In 2016 “GFTC” was recognized by the “National Business Ratings Union” as a leader in the financial and economic sector and won a gold medal.
      
      Our goal is to provide financial organizations with a full range of services in the field of banking technologies.
      
      Our credo is to be leaders in the field of financial instruments; to provide customers with high quality service tailored to them; to create the best working environment for our employees.
            
      `,
      aboutdescriptionthree: ` Members of the Supervisory Board:
   
      •	Chairman - Teona Esiashvili
      
      •	Deputy Chairman - Nino Alkhazashvili
      
      •	Member - Nikoloz Tutberidze
      `,
      //Separate Services
      'swiftref': 'SWIFT Ref',
      'swiftdescription': 'Financial Data References',
      'transguardservice': 'TransGuard',
      'transguarddescription': 'Transaction Monitoring',
      //News
      news:'News',
      'newstransguardtitle':`SIBOS 2022
      
      `,
      'newstransguarddescription':"After a pause caused by the pandemic, the first SIBOS was held in 2022. The event, held in Amsterdam, was attended by more than 10 000 delegates.....",
      'newstransguarddescriptionfull':`After a pause caused by the pandemic, the first SIBOS was held in 2022. The event, held in Amsterdam, was attended by more than 10 000 delegates. Georgian representatives from SWIFT Service Bureau and National Bank also took part in the conference.
      At this year’s event the main emphasis was made on the following items: 
      
      •	CBDC and tokenized assets;
      
      •	Fast growth of international payments/transfers of small volume;
      
      •	Maximum simplification of operations through Pre-validation, Case management and Payment Control products;
      
      •	Optimization of intermediary rates through increased transparency;
      
      •	ISO2022 and its significant advantages over MT Format;
      
      •	Transformation of SWIFT into an innovative platform focused on data efficiency;
      
      •	Financial crime and the need to fight  against it together.
      `,
      'button':'Read more',
      'newsswifttitle':`Sanctions and Regulations under Accelerated Operations`,
      'newsswiftdescription':"Complexity of financial crime is increasing along with development of security tools. Different regulations between countries and the accelerated transactions.....",
      'newsswiftdescriptionfull':`
       Complexity of financial crime is increasing along with development of security tools. Different regulations between countries and the accelerated transactions make it more difficult to detect suspicious transactions.

       In order to respond to the challenges, it is necessary to use legislation and sanctioned lists specific to region. At the same time, against the backdrop of high pace and increasing standard of service, verification of transactions is associated with a lot of effort.

       It is impossible to cope with the mentioned risks and data flows manually with only human resources. That is why automated compliance systems have become an integral part of financial organizations’ activities.

       Modern software, such as “Transguard”, based on international lists and artificial intelligence, allows verification of each transaction or client profile in milliseconds. Such an automated procedure is characterized by versatility and high accuracy.

       Financial crime is constantly evolving, but using a reputable compliance system minimizes risks.
`,
      'button':'Read more',
      'newskyctitle':`People behind the Business
      
      `,
      'newskycdescription':"On December 15,  the exhibition of  sales association – “People behind the business” was held at  Biltmore hotel, where about 50 companies, including ......",
      'newskycdescriptionfull':`On December 15, the exhibition of sales association – “People behind the business” was held at Biltmore hotel, where about 50 companies, including “GFTC”, took part.
      
      The visitors of the exhibition had the opportunity to meet the managers of the participating organizations, get familiarized with their services, products, or simply share their experience.
      
      Within the framework of the event, there was a special space for B2B meetings and negotiations.
      
      Guests who came to the exhibition were given branded souvenirs at the “GFTC” stand.
      `,
      'newsaml':'AML / KYC Challenges - Opportunity for Digital Banks ',
      'newamlcdescription':`By reducing costs and simplifying  service, digital banks are constantly trying to make their activities more attractive to customers......`,
      'newamlcdescriptionfull':`By reducing costs and simplifying  service, digital banks are constantly trying to make their activities more attractive to customers.
      The Covid-pandemic of recent years has made the necessity and possibilities of digital transformation even more visible.
      
      Digitization creates additional opportunities for both customers and cybercrime.
      
      In 2021, despite a record sum of 50 billion USD spent worldwide against Money Laundering, financial institutions had to pay more than 10 billion USD in fines. This means that the existing AML systems cannot be used as effectively as possible. At the same time, according to a survey conducted by “Refinitiv” in 2021, 86% of respondents believe that modern technologies significantly help them detect financial crimes.
      To reduce risks, each transaction or document should be checked for the following aspects:
      
      -	In case of remote service, verification of validity and authenticity of the client (Liveness/Facial check);
      
      -	Verification of the client (participant of transaction) according to black lists and lists of politically active persons;
      
      -	Checking their backgrounds with media sources;
      
      -	Calculation of  appropriate, clear risk score of the client.
      
      Due to complexity of the work, the following question arises: how can a financial institution focus on its own business and customers to best meet all AML requirements? The answer to this question is automation of the process, selection of the right information and technology. Against the backdrop of increased information flows, there is no alternative to digital data processing. Even in small institutions, use of comprehensive databases and artificial intelligence can significantly save time and money.
      Based on the aforementioned, future development of the financial sector is largely related to digital transformation.
      `,
      'button':'Read more',
      allnews:'All news',
      //Footer Contact information
      contactinformation:'Contact Information',
      contactaddress:'Chavchavadze Ave. #37B, Tbilisi 0162',
      rights:`All Rights Reserved © ${year} LTD "Georgian Financial Telecommunication Company GFTC"`,
      //Flag
      flag: require('../pictures/geo.svg').default,
      //Customers and clients
      customers:'Customers',
      partners:'Partners',
      sliderTitle:`Summary of 2022`,
      sliderDescription:`800+ solved problems 150+ Updates`,
      sliderTitleTwo:`ISO20022`,
      sliderDescriptionTwo:`Use of the MX format has become mandatory from March,
      2023. Are you ready for a change?`,
      sliderTitleThree:`Completely new BLS `,
      sliderDescriptionThree:`Improved match accuracy, increased
      security, refined interface...`,
      serviceSwift:'SWIFT Service Bureau',
      serviceDescription:'SWIFT is a worldwide network used by banks and other organizations to exchange financial information quickly and securely. Securities, treasury, transfers and many other types of operations are carried out based on SWIFT notifications.',
      swiftComunication:`SWIFT Backup Communication

      `,
      swiftComunicationDescription:"All organizations involved in  SWIFT  have a diverse and expensive technological infrastructure.....",
      swiftComunicationFullDescription:`All organizations involved in  SWIFT  have a diverse and expensive technological infrastructure, the most complex part of which is the communication components.
      </br></br>
      The speed and quality of financial operations are based on stability of the connection. SWIFT communication hardware is distinguished by closed interfaces and special complexity, therefore,   elimination of accidents is associated with significant time and financial costs. 
      </br></br>
      To reduce risks “GFTC”  offers backup communication service to member organizations of  SWIFT. In case of using the backup communication, the customer has the opportunity to carry out daily activities using its own infrastructure, although, in case of a problem in the network, to continue the connection with SWIFT services through the backup infrastructure of “GFTC”.
      `,
      swiftComunicationInfrastructure:`SWIFT  Communication Infrastructure
      
      `,
       swiftComunicationInfrastructureDescription:"All organizations involved in SWIFT have a diverse and expensive technological infrastructure, the most complex part of which......",
       swiftComunicationInfrastructureDescriptionFull:`All organizations involved in SWIFT have a diverse and expensive technological infrastructure, the most complex part of which is the communication components.
       </br></br>The speed and quality of financial operations are based on stability of the connection. SWIFT communication hardware is distinguished by closed interfaces and special complexity; therefore, elimination of accidents is associated with significant time and financial costs. 
       </br></br>In order to minimize risks and increase reliability, “GFTC” offers SWIFT member organizations to use  communication infrastructure existing on the base of “SWIFT Service Bureau”.
       </br></br>In case of using the Service Bureau’s communication infrastructure, the customer is  exempted  from  risks and costs associated with  SWIFT licenses, hardware, non-profile employees.
       </br></br>Along with simplified processes and reduced costs, customer receives fully-duplicated infrastructure, high quality support and regular updates of “GFTC”.
       `,
       swiftFullInfrastructure:`Complete Infrastructure of  SWIFT
        
       `,
       swiftFullInfrastructureDescription:'All organizations involved in SWIFT have a diverse and expensive technological infrastructure. Effectiveness of financial.....',
       swiftFullInfrastructureDescriptionFull:`All organizations involved in SWIFT have a diverse and expensive technological infrastructure. Effectiveness of financial activity depends on its proper functioning.
       </br></br>SWIFT hardware is characterized by closed interfaces and special complexity; therefore, its daily management, updates and configuration are associated with significant difficulties. The situation is getting complicated by frequent mandatory updates, which need to be installed at least seven times a year.
       </br></br>In addition to staff with rare qualifications, infrastructure services require quite a lot of server and network resources. In  case of replication, requests are doubled. All this, in turn, is associated with significant financial costs.
       </br></br>In order to reduce costs and maximize reliability, “GFTC” offers its customers  to use its own SWIFT infrastructure. When using the service, only a personal computer or terminal server is required to work with SWIFT, and everything else is located in fully duplicated  data centers of  “GFTC”.
       </br></br>Unlike other service bureaus, infrastructure of “GFTC”  is unique and offers each customer a customized, fully isolated, secure server environment.
       </br></br>Using the full infrastructure of the Service Bureau exempts the customer from  the costs  of SWIFT licenses, updates, backups and communications. Along with cost savings, the service includes high – quality, personalized support.
        `,
        transguardDescription:'“Transguard” system is AML and KYC compliance software. The mentioned product has existed and developed for more than 10 years. Nowadays it is one of the leaders of the financial technology sector in Georgia.',
        blsBlackList: `BLS - Comparison to Black Lists
        
       `,
       blsBlackListDescripton:"“BLS” is a combination of algorithms, the purpose of which is to scan transactions or clients and compare them to different types of lists. As part of the service.....",
       blsBlackListDescriptionFull:` “BLS” is a combination of algorithms, the purpose of which is to scan transactions or clients and compare them to different types of lists.
       </br></br>As part of the service, in terms of lists,  the lists of international market leader “Refinitiv” (London Stock Exchange Group) (formerly Thomson Reuters, World-Check) are used.
       </br></br>The product includes more than 1000 sanctioned, politically active persons and other types of lists. The sources of the lists are presented by both reputable international organizations and various countries, including Georgia. The lists are updated automatically and twice a day depending on their criticality.
       </br></br>The service has two independent modes:
       </br></br>•	Automatic - the system is implemented in the customer’s or the third party’s infrastructure and is integrated with the  customer’s main system. Scanning of any record (client onboarding, any type of operation or transaction) within the mentioned scheme is done in real time and automatically. The system also provides  daily scanning of complete base of  customer’s clients.
       •	Manual - (so-called online user) in the case of the specified package, the unified system is implemented on the side of the Service Bureau, and the customer has access to the service remotely, through the website. Verification of clients in the lists and comparison with the lists is performed on the basis of manual data entry.
       `,
       fmrReports:`FMR - FMS reporting
        
       `,
       fmrDescription:"The purpose of “FMR” is automatic detection of suspicious and above-threshold operations/transactions requested by the Financial Monitoring Service of Georgia.....",
       fmrDescriptionFull:"The purpose of “FMR” is automatic detection of suspicious and above-threshold operations/transactions requested by the Financial Monitoring Service of Georgia. The system is implemented in the customer’s or third party’s infrastructure and is  integrated with its main operating system. “FMR” collects information about the performed operations and transactions within 24 hours and analyzes them together at the end of the day. At the end of the process, the service automatically generates FMS reporting form. The system is integrated with FMS infrastructure, making it easy to send an already prepared reporting form with one click.",
       kycCustomerRisk:`KYC - Customer  Risk Assessment
       
       `,
       kycCustomerRiskDescription:'The system is created on the basis of local and international standards and algorithms  of different classes are fully utilized in it. KYC is implemented on the customer .....',
       kycCustomerRiskDescriptionFull:'The system is created on the basis of local and international standards and algorithms  of different classes are fully utilized in it. KYC is implemented on the customer side and integrated with the underlying operating system. The purpose of the system is  managment of  clients’ bases, in particular, risk assessment. Risk analysis is carried out on the basis of individual and international approaches. Analytics of completed transactions is based on historical transactions that have already been made.',
      },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "geo", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
