// import { useState } from "react";
import { useTranslation } from "react-i18next";
import Servicesitem from "../servicesitem/Servicesitem";
import { Container, Row } from "react-bootstrap";

const Services = ({ data }) => {
  const { t } = useTranslation();
  // const [show, setShow] = useState(false);
  return (
    <>
      <Container className="mt-55">
        <h1 className="d-flex jc-center txt-center">{t(data.title)}</h1>
      </Container>
      <Container fluid className="primary-background mt-16">
        <Container className="services-title">
          <p className="d-flex jc-center txt-center">{t(data.description)}</p>
        </Container>
      </Container>
      <Container className="padding-02">
        <Row>
        {data?.information?.map((item, key) => (
          <Servicesitem item={item} key={key} />
        ))}
        </Row>
      </Container>
    </>
  );
};

export default Services;
