import { motion } from "framer-motion";
import Services from "../components/services";
import { services_data } from "../data/services-info";

const Swift = () => {
  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
      >
        <Services data={services_data[1]} />
      </motion.div>
    </div>
  );
};

export default Swift;
