import React, { useState } from "react";
import { Container, Row, Col, Modal, Form, CloseButton } from "react-bootstrap";
import { LanguageSwitcher } from "../../components";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../pictures/logo.png";
// import Application from "../../pictures/application.svg";
// import Uk from "../../pictures/united-kingdom.svg";
import Menuboard from "../../pictures/menuboard.svg";
import DropdownArrow from "../../pictures/dropdownarrow.svg";
import Headphone from "../../pictures/message.svg";
import { useTranslation } from "react-i18next";

const Navbar = ({hidden, setHidden, swiched, setSwiched}) => {
  // const [about, setAbout] = useState();
  const [show, setShow] = useState(false);
  const [fix, setFix] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);

  // const [bounce, setBounce] = useState(false);

  

  // useEffect(() => {
  //   if (bounce) {
  //     setTimeout(() => {
  //       setBounce(false);
  //     }, 1000);
  //   }
  // }, [bounce]);

  const setFixed = () => {
    if (window.scrollY >= 1) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  // useEffect(() => {
  //   if (about) {
  //     setTimeout(() => {
  //       window.scroll(0, 500);
  //     }, 200);
  //   }
  // onClick={() => setAbout(Math.random())}
  // }, [about]);

  window.addEventListener("scroll", setFixed);
  // console.log(fix);

  const { t } = useTranslation();

  return (
    <nav className={fix ? "navbar-fixed" : ""}>
      {/* <Container className="absolute"> */}
      <Col className="absolute d-flex jc-end mt-150 dropdown-menu">
            <div>
              <img
                onClick={() => setHidden(!hidden)}
                className="menuboard"
                src={Menuboard}
                alt="menuboard"
              />
            </div>
          </Col>
          {/* </Container> */}
      <Container fluid>
      
      
      <Container>
        <Row >
          <Col lg={1}>
            <Link to="/">
              <img className="logo" src={Logo} alt="logo" />
            </Link>
          </Col>
          <Col
            lg={11}
            className={
              hidden
                ? "d-flex jc-end navbar-media"
                : "d-flex jc-end navbar-media dropdown-active"
            }
          >
            <ul className="active-tab">
              <li>
                <NavLink onClick={() => setHidden(!hidden)} to="/">{t("home")}</NavLink>
              </li>

              <li>
                <NavLink onClick={() => setHidden(!hidden)} to="/news">{t("news")}</NavLink>
              </li>
              <li
                onMouseOver={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
                // onClick={() => setDropdown(true)}
              >
                <button className="dropdown-btn">
                  {t("services")}{" "}
                  <img
                    className={dropdown ? "arrow-open" : "arrow-open-close"}
                    src={DropdownArrow}
                    alt="arrow"
                  />
                </button>
                <div className={dropdown ? "dropdown activee" : "dropdown"}>
                  <ul>
                    <li>
                    <NavLink onClick={() => setHidden(!hidden)} to="swift">{t("swift")}</NavLink>
                    </li>
                    <li>
                    <NavLink onClick={() => setHidden(!hidden)} to="transguard">{t("transguard")}</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                onMouseOver={() => setDropdownAbout(true)}
                onMouseLeave={() => setDropdownAbout(false)}
              >
                <button className="dropdown-btn">
                  {t("about")}{" "}
                  <img
                    className={
                      dropdownAbout ? "arrow-open" : "arrow-open-close"
                    }
                    src={DropdownArrow}
                    alt="arrow"
                  />
                </button>
                <div
                  className={dropdownAbout ? "dropdown activee" : "dropdown"}
                >
                  <ul>
                    <li>
                      <NavLink onClick={() => setHidden(!hidden)} to="/about">{t("aboutcompany")}</NavLink>
                    </li>
                    <li>
                      <NavLink onClick={() => setHidden(!hidden)} to="/customersandclients">{t("customresandclients")}</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <NavLink onClick={() => setHidden(!hidden)} to="/contact">{t("contact")}</NavLink>
              </li>
            </ul>
            <div className="button-wrapper casemanager">
              <button>
                <img src={Headphone} alt="headphone" />
                <a href="http://casemanager.gftc.ge/" target="_blnak">
                  {t("casemanager")}
                </a>
              </button>
            </div>
            {/* <div className="button-wrapper">
              <button onClick={() => setShow(true)}>
                <img src={Application} alt="application" />
                {t("statement")}
              </button>
            </div> */}
            {/* <div
              onClick={() => setBounce(!bounce)}
              className={bounce ? "bounce-active" : "bounce"}
            >
              <img className="uk" src={Uk} alt="application" />
            </div> */}
            <LanguageSwitcher setSwiched={setSwiched} swiched={swiched} />
          </Col>
         
        </Row>
      </Container>
      </Container>
      
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header className="modal-wrapper">
          <CloseButton
            variant="white"
            onClick={() => setShow(false)}
          ></CloseButton>
          <Modal.Title>{t("statement")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-description">
          <Form className="form-font">
            <Row>
              <Form.Label>{t("companydata")}</Form.Label>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("companyname")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder={t("identificationcode")}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("country")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("cityregion")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="underline">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("address")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col className="underline">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("officenumber")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Form.Label>{t("personaldata")}</Form.Label>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("name")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("lastname")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder={t("officialposition")}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={t("email")} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Select>
                  <option>{t("products")}</option>
                  <option>BLS</option>
                  <option>KYC</option>
                  <option>FMR</option>
                  <option>NBR</option>
                  <option>SWIFT</option>
                </Form.Select>
              </Col>
              <Col className="jc-end d-flex">
                <button type="submit">{t("send")}</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </nav>
  );
};

export default Navbar;
