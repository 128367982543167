import { useEffect } from "react";
// import Uk from "../../pictures/united-kingdom.svg";
// import Geo from "../../pictures/geo.svg";
// import Rus from "../../pictures/rus.svg";

import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({swiched, setSwiched}) => {
  // console.log("swiched" + swiched)
  // const [bounce, setBounce] = useState(false);

  useEffect(() => {
    if (swiched) {
      setTimeout(() => {
        // setBounce(false);
        setSwiched(false);
      }, 200);
    }
  }, [swiched]);

  const { t, i18n } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng

  const onClick = (event) => {
      i18n.changeLanguage(getLanguage() === 'geo' ? 'en' : 'geo')
      setSwiched(!swiched)
  }

  return (
    <>
      <div>
        <div
          className="bounce switcher"
          // onClick={() => setBounce(!bounce)}
        >
          {/* <img onClick={onClick} src={Geo} alt="pic"></img> */}
          {/* <img onClick={onClick} src={Rus} alt="pic"></img> */}
          <img onClick={onClick} src={t('flag')} alt="pic"></img>
        </div>
      </div>
    </>
  );
};

export default LanguageSwitcher;
