import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { news_data, slider_data, single_services } from "../data";
import About from "../pictures/about.jpg";
import { NewsItem, Swiperslider } from "../components";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
// import useFetch from "../hooks/useFetch";

const Main = () => {
  const { t } = useTranslation();
  // console.log(news_data)
  // let {loading, error, data} = useFetch('http://localhost:1337/api/newsitems?populate=*');

  return (
    <div className="overflow-x-hidden-pages">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { transition: "0.1" } }}
      >
        <Container fluid>
          <Container className="mt-55">
            <Row className="shadow-for-container">
              <Swiper
                className="spier-height"
                modules={[Navigation, Autoplay, Pagination]}
                spaceBetween={50}
                pagination={{ clickable: true }}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                navigation
              >
                {slider_data.map((e, key) => (
                  <SwiperSlide key={key}>
                    <div className="shadow-layer d-flex jc-center padding-left-tworem flex-direction-columnn">
                    <h1 className="title-font-size">{t(e.title)}</h1>
                    <p className="title-font-size">{t(e.description)}</p>
                    </div>
                    <img className="pic" src={e.img} alt={e.id} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Row>
            <Row className="mt-55" id="about">
              <Col className="d-flex jc-end media-jc-center ">
                <div className="shadow-for-container img-wrapper-about">
                  <img src={About} alt="img" />
                </div>
              </Col>
              <Col className="media-jc-center d-flex">
                <div className="about-wrapper  ">
                  <h2 className="title-font-size">{t("aboutcompany")}</h2>
                  <p className="mt-12 hyperlink" dangerouslySetInnerHTML={{__html: t("aboutdescription")}}></p>
                  <Col className="d-flex jc-end">
                    <Link to="about">
                      <button className="mt-12">{t("button")}</button>
                    </Link>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container
          fluid
          className="container-color p-tb-20 mt-55 service-wrapper"
        >
          <Container>
            <Row>
              <Col className="d-flex jc-center main-title">
                <h1 className="title-font-size">{t("services")}</h1>
              </Col>
            </Row>
            <Row className="d-flex jc-center flex-direction-column">
              <Col></Col>
              {single_services.map((e, key) => (
                <Col key={key} className="jc-center d-flex media-jc-center  pb-40">
                  <Link to={e.link}>
                    <div className="box shadow-for-container">
                      <h2>{t(e.title)}</h2>
                      <img src={e.img} alt="cm" />
                      {/* <p>{t(e.description)}</p> */}
                    </div>
                  </Link>
                </Col>
              ))}
              <Col></Col>
            </Row>
          </Container>
        </Container>

        <Container className="mt-55">
          <Row>
            <Col>
              <h1 className="d-flex jc-center txt-center title-font-size">
                {t("news")}
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-2">
            {news_data.slice(0, 3).map((item, key) => (
              <NewsItem key={key} item={item} />
            ))}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="d-flex jc-end mt-24">
              <button className="news">
                <Link to="news">{t("allnews")}</Link>
              </button>
            </Col>
          </Row>
        </Container>

          <Container className="mt-55  overflow-hidden">
            <Row>
              <Col>
                <h1 className="d-flex jc-center txt-center title-font-size">
                  {t("customresandclients")}
                </h1>
              </Col>
            </Row>
            <Row>
              <Swiperslider />
            </Row>
          </Container>
      </motion.div>
    </div>
  );
};

export default Main;
