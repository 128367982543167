import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Row, Container } from "react-bootstrap";
import { partnerslider } from "../../data";
import { Navigation, Autoplay, Pagination } from "swiper";
import { useEffect, useState } from "react";

export default () => {
  const [swiperBody, setSwiperBody] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSwiperBody(
        <>
          <Swiper
            className="swiper-arrow mt-20"
            modules={[Navigation, Autoplay, Pagination]}
            grid
            // pagination={{ clickable: true }}
            loop={true}
            navigation
            grabCursor={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              waitForTransition: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: -190,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: -190,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: -190,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: -190,
              },
            }}
          >
            {partnerslider?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="sliderimgs">
                  <div>
                    <img src={slide.img} alt={slide.title} />
                  </div>

                  {slide.imgtwo && slide.titletwo && (
                    <div>
                      <img src={slide.imgtwo} alt={slide.titletwo} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      );
    }, 1000);
  }, []);
  return (
    <>
      <Container className="d-flex jc-center">
        <Row className="mt-34 unhide">
          <Col>{swiperBody}</Col>
        </Row>
      </Container>
    </>
  );
};
